@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&family=Nothing+You+Could+Do&display=swap");
:root {
  --poppins-font: "Poppins", sans-serif;
  --josefin-font: "Sansita Swashed", cursive;
  --nothing-font: "Nothing You Could Do", cursive;
  --white: #ffffff;
  --light-gray: #919191;
  --whitish-gray: #bfbfbf;
  --main-bg-light: #eef0d9;
  --main-text-orange: #f5b750;
  --main-text-red: #d93133;
  --light-black: #121618;
  --lightish-black: #171b1d;
  --pizza-yellow: #ffe600;
  --yellow: #fac564;
  --green: #1d9f55;
  --pizza-red: #ff6240;
  --pizza-red-light: #f87d62 0;
  --transition: all ease-in-out 0.3s;
  --red: #ff0000;
  --modal-glass-bg: rgba(255, 255, 255, 0.15);
  --modal-box-shadow: -2px 10px 24px -11px #0000009e;
  --transparent-button: #ffffffa2;
}

/* Reset */
html {
  font-size: 100%;

}

* {
  margin: 0;
  padding: 0;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("./assets/images/landing-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-position: center;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

img {
  display: block;
}

/* Flex */
.flex-container {
  display: flex;
}

/* Flex Row */
.flex-row {
  flex-direction: row;
}

/* Flex Column */
.flex-column {
  flex-direction: column;
}
/* Text Center */
.txt-center {
  text-align: center;
}

/* White */
.txt-white {
  color: var(--white);
}

/* Poppins Font */
.pop-font {
  font-family: var(--poppins-font);
  font-display: swap;
}
.active-button-style {
  cursor: pointer;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 1.2rem;
  background-color: var(--pizza-red);
  border-color: var(--pizza-red);
  letter-spacing: 1px;
  align-items: center;
  width: max-content;
  color: var(--light-black);
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--poppins-font);
}
.passive-button-style {
  cursor: pointer;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 0.5rem 1.2rem;
  opacity: 0.8;
  background-color: transparent;
  border-color: var(--white);
  color: var(--white);
  letter-spacing: 1px;
  align-items: center;
  width: max-content;
  font-size: 1rem;
  font-family: var(--poppins-font);
}

.section-description {
  color: var(--light-gray);
  font-family: var(--poppins-font);
  font-size: 1rem;
  text-align: center;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.webflow-style-input {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border-radius: 5px;
  opacity: 0.8;
  width: 100%;
  padding: 0.5rem 1.2rem;
  background-color: rgba(20, 20, 20, 0.672);
}
.webflow-style-input:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(to right, #f56456, #dfe657, #ee5e41, #ffff10, #b294ff, #57e6e6);
  background-size: 500% auto;
  -webkit-animation: gradient 3s linear infinite;
  animation: gradient 3s linear infinite;
}

.webflow-style-input input,
.webflow-style-input textarea {
  border-style: none;
  background: transparent;
  outline: none;
  color: var(--whitish-gray);
  flex-grow: 1;
  line-height: 2.4rem;
  vertical-align: middle;
  font-size: 1rem;
  font-family: var(--poppins-font);
}

/* Scroll Button */
.scroll-button {
  background-color: var(--light-black);
  border-radius: 50%;
  border: 1px solid var(--whitish-gray);
  color: var(--pizza-red);
  position: fixed;
  right: 10px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  font-size: 20px;
  padding: 1.3rem;
  background-image: url("./assets/images/scroll-arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35%;
  opacity: 0.5;
}

/* Transitions */
.txt-white,
.active-button-style,
.passive-button-style {
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.active-button-style:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}

.passive-button-style:hover {
  background-color: var(--pizza-red);
  border-color: var(--pizza-red);
}

/* Hover */
@media (hover: hover) {
  .active-button-style:hover,
  .passive-button-style:hover {
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }
}

/* ADD TO CART BUTTON */
.passive-button-style.active-add-to-cart {
  background-color: var(--green);
  border-color: var(--green);
  cursor: pointer;
}
.passive-button-style.active-add-to-cart:hover {
  opacity: 0.7;
  transition: all ease-in-out 0.2s;
}

.passive-button-style.inactive-add-to-cart {
  color: rgba(255, 255, 255, 0.22);
  border-color: rgba(255, 255, 255, 0.22);
  background-color: rgba(255, 255, 255, 0.22);
  cursor: not-allowed;
}

/* Success Msg */
.success {
  display: none;
}
.success.visible {
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(5px);
  padding: 0.5rem;
  border-radius: 10px;
}
.success.visible p {
  font-family: var(--poppins-font);
  font-size: 1rem;
  color: var(--white);
  font-weight: 500;
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
.terms h2 {
  font-size: 3rem;
  color: var(--white);
  text-align: center;
  font-family: var(--poppins-font);
}
.terms p,
.terms h3 {
  color: var(--light-gray);
}
