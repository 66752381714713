/* Header */

header {
    height: 4.5rem;
    background-color: transparent;
    position: sticky;
    top:0;
    z-index:1001;
    padding: 0.5rem;
    transition: background-color 1s ease-in-out;
}
.scrolled {
    background-color: #eef0d9;
  }
  a {
    cursor: pointer;
  
  }
  .text-color{
    color:black
  }


.header__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
/* Logo */
.logo-styling {
    align-items: center;
    width: 6rem;
    margin:0px;
    padding: 0;
}
.logo {
    width: 50%;
    height: auto;
    transition: all ease-in-out 0.2s;
    margin: 0px;
}
.h1 {
    font-size: 1.2rem;
    font-family: var(--josefin-font);
    line-height: 1.2rem;
    letter-spacing: 2px;
}
.logo-styling h1 span {
    display: block;
    
}

/* NAVIGATION MENU */
.header__nav__menu {
    display: none;
    gap: 2rem;
    font-weight: 400;
    font-size: 1.1rem;
    align-items: center;
}
.header__nav__menu.active {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 99;
    background-color: #eef0d9;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    height: 100vh;
}
.header__nav__menu a {
    transition: all ease-in-out 0.2s;
}
/* Login & Cart */
.login-and-cart {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
h1{
    color: var(--pizza-red)
}
.cart-btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: 600;
}
.cart-btn img {
    width: 1.5rem;
    height: 1.5rem;
}

.header__nav__hamburger {
    width: 100%;
    max-width: 50px;
    height: auto;
    cursor: pointer;
    margin-right: 0;
    object-fit: contain;
}
.header__nav__menu.active .header__nav__hamburger {
    content: url("../../assets/images/close-btn.svg");
}
.hero__info {
    padding: 1rem;
}
.hero__info {
    position: absolute;
    z-index: 4;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
    overflow-x: hidden;
}
.hero__info span {
    color: var(--pizza-red);
    font-family: var(--nothing-font);
    font-size: 2rem;
}

.hero__info h1 {
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 3rem;
    color: var(--pizza-red);
}

.hero__info p {
    font-size: 1rem;
}

.hero__interaction {
    gap: 1.3rem;
    box-sizing: content-box;
}

.hero__interaction a:first-child {
    color: var(--light-black);
    background-color: var(--yellow);
    border: 1px solid transparent;
}

.hero__interaction a:last-child {
    border-width: 1px;
    border-color: var(--transparent-button);
    background-color: var(--transparent-button);
    color: var(--light-black);
}
.hero__interaction a:last-child,
.hero__interaction a:first-child,
.header__nav__menu a,
.logo,
.login-and-cart .cart-btn {
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}

@media screen and (hover: hover) {
    .header__nav__menu a:hover,
    .logo:hover,
    .hero__interaction a:last-child:hover,
    .hero__interaction a:first-child:hover {
        -moz-transition: all ease-in-out 0.3s;
        -o-transition: all ease-in-out 0.3s;
        -webkit-transition: all ease-in-out 0.3s;
        -ms-transition: all ease-in-out 0.3s;
        transition: all ease-in-out 0.3s;
    }
    .header__nav__menu a:hover {
        color: var(--pizza-red);
    }
    .logo:hover {
        transform: rotate(30deg);
    }

    .hero__interaction a:last-child:hover {
        color: var(--light-black);
        background-color: var(--yellow);
        border: 1px solid transparent;
    }
    .hero__interaction a:first-child:hover {
        border: 1px solid var(--yellow);
        color: var(--light-black);
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.637);
        background-color: rgba(255, 255, 255, 0.637);
    }
    .login-and-cart a:hover {
        background-color: var(--pizza-red);
        border-color: var(--pizza-red);
        color: var(--white);
        opacity: 0.5;
        -moz-transition: all ease-in-out 0.3s;
        -o-transition: all ease-in-out 0.3s;
        -webkit-transition: all ease-in-out 0.3s;
        -ms-transition: all ease-in-out 0.3s;
        transition: all ease-in-out 0.3s;
    }
    .cart-btn:hover {
        opacity: 0.8;
        color: var(--light-black) !important;
    }
}

@media screen and (min-width: 1025px) {
    .header__nav__menu {
        display: flex;
    }
    .hero__info h1 {
        font-size: 2.5rem;
    }
    .header__nav__hamburger {
        display: none;
    }
    .header__nav {
        width: 100%;
        z-index: 999;
    }

    .hero__info p {
        width: 70%;
        margin: 0 auto;
    }
    .logo-styling {
        gap: 1rem;
    }
    .logo {
        width: 80%;
    }
    .logo-styling h1 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
