/* Section 10 */
.homepage__contact {
    display: flex;
    flex-direction: column;
    padding: 4rem;
    gap: 1rem;
    max-width: 1440px;
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box; 
   
}

.h1{
    color:white;
}

.map-container {
    position: relative;
    width: 90%;
    height: 350px; 
    margin: 0 auto;
    border: 2px solid #ccc; 
    border-radius: 10px; 
    overflow: hidden;
  }
  
  .leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 10px; 
  }


  .mapHeading{
    margin-left: 8rem;
    color: var(--main-text-red);
    font-size: 3rem;
}


.homepage__contact h2 {
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 400;
    text-align: left;
}

#map {
    height: 30rem;
    max-width: 90%;
    margin: 0 auto;
}

button{
    width:90%;
    height: 3rem;
    margin:2% 5%;
    background-color: var(--main-text-red);
}
.contact__info {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--poppins-font);
    text-align: justify;
}
.contact__info h3 {
    font-size: 2rem;
}
.contact__info h3,
.contact__info h4 {
    color: var(--white);
    font-weight: 500;
}
.contact__info p {
    color: var(--light-gray);
}
.contact__info ul {
    display: flex;
    flex-direction: column;
}
.contact__info ul li {
    color: var(--light-gray);
    text-decoration: none;
}

.homepage__contact .active-button-style {
    margin-top: 2rem;
}

@media screen and (min-width: 700px) {
    .homepage__contact {
        justify-content: space-evenly;
        gap: 5rem;
    }
}
@media screen and (min-width: 1025px) {
    .homepage__contact {
        flex-direction: row !important;
    }
    .map {
        height: auto;
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .homepage__contact {
        justify-content: space-evenly;
        gap: 2rem;
        padding: 4rem 0 3rem 0
    }
    .map-container{
        height:400px;
        margin-top:0;
    }
    .mapHeading{
        margin-left: 3rem;
        color: white;
        font-size: 2rem;
    }
}