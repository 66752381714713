/* Footer */
footer {
    background-color: transparent;
    gap: 2rem;
    padding: 1rem 2rem;
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available;
 
    top: 100%;
}

.footer__menu {
    gap: 1rem;
    font-weight: 400;
    font-size: 1rem;
    align-items: center;
}

.footer__menu a {
    transition: all ease-in-out 0.2s;
    letter-spacing: 1.5px;
}

.footer__menu a:hover {
    color: var(--pizza-red);
    transition: all ease-in-out 0.2s;
}

.footer__contact {
    display:none;
    gap: 1rem;
    font-weight: 400;
    font-size: 1rem;
}
.logo-styling.footer {
    margin: 0 auto;
    margin-top: 2rem;
}
.footer__contact p {
    color: var(--white);
}

hr {
    opacity: 0.2;
}


.footer__socials {
    display: flex;
    justify-content: center;
    justify-items: center;
    gap: 2rem;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
}


@media screen and (min-width: 700px) {
    footer {
        flex-direction: row  !important;
        justify-content: space-evenly;
    }
    .footer__contact{
        display:block;
    }
}
