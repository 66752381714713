.homepage__welcome {
    overflow: hidden;
    

}
/* Section 2 */
.welcome__cover {
    width: 70%;
    object-fit: cover;
    z-index: 2;
    opacity: 0.8;
    margin: 0 15%;
   
  
 
}
.imgCover{
    width: 96%;
    max-height: 30rem;
    margin: 2%;

  
}
.welcome__info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 10rem;
   
}


.welcome__info h2 {
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0 20px;
}

.welcome__info h2 span {
    font-family: var(--nothing-font);
    color: var(--yellow);
}

.welcome__info p {
    color: var(--light-gray);
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    text-align: justify;
    padding:1%;
}

.welcome__info img {
    display: none;
    position: absolute;
    overflow: hidden;
    width: 20%;
    height: auto;
    object-fit: contain;
    top: 3rem;
}
h5{
   font-weight: 400;
    font-size: 1.5rem;
    color: var(--main-text-red);
    font-style: italic;
    margin:0;
}
.bravoPizza{
    font-size: 3rem;
}

.imageHolder {
    display: grid;
    grid-template-columns: 70% 30%; 
    height:90vh;
    padding:2%;
    max-width: 100vw;
  }
  
  
  .Col1,
  .col2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .imgCover {
    width: 94%; 
    object-fit: cover; 
    height: 40%; 
    margin: 0 2% 1% 0;
    max-height: 25rem;
    
  }
  
  .imgStaff {
    width: 100%; 
    object-fit: cover;
    height: 84%; 
   
  }
 


@media (max-width: 700px) {
    .welcome__info img {
        display: block;
    }
    .welcome__cover {
      width: 90%;
      object-fit: cover;
      z-index: 2;
      opacity: 0.8;
      margin: 0% 2%;

  }
    .welcome__info{
        padding : 2px;
        font-size: 2rem;
        font-weight: 300;
        margin:0;
        display: grid;
    }
    .bravoPizza{
        font-size: 4rem;
        font-weight: 400;
    }
    .imageHolder {
       
        height:fit-content;
        padding:0;
        min-width: 100vw;
        display: grid;
        grid-template-rows: 1fr 1fr; 
        padding:0;
        left: 0;
      }
       .Col2 {
        display: block;
        width: 98vw;
        margin: 0;
        grid-column: 1 / 1; 
        grid-row: 1 / 2; 
        left: 0;
        height:fit-content
      }
      .Col1{
        display: block;
        width: 140%;
        overflow: hidden;
        margin:1vw;
        grid-column: 1/1; 
        grid-row: 2 / 2; 
       
      }
      .imgCover{
        height:30vh;
      
      }
      
      .imgCover, .imgStaff {
        width: 100%;
        display: block;
        margin-bottom: 1rem;
      }
    
       
      
}
