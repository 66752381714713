li {
  list-style: none;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
  background-image: url("../../assets//images/landing-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 4rem;
  max-width: 1440px;
  margin: 0 auto;
 
}
.menu__categories {
  grid-column: 1 / 2; 
  grid-row: 1 / 1;  
}

.menu__items {
  grid-column: 2 / 2;
  grid-row: 1 / 2;   
}

.toppings-section {
  grid-column: 3/ 3;
  grid-row: 2 / 3;
}

.menu__categories h1 {
  font-family: var(--josefin-font);
  text-align: left;
}
.menu__categories {
  grid-column: span 1;
  background-color: transparent;
  color: var(--white);
  width: 100%;
  justify-content: space-around;
  margin-top: 2rem;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.heading{
  color:var(--main-text-orange);
  margin:3%;
    display: flex;
    justify-content: center;
  font-size: x-large;
}
/* ScrollBar */

.menu__categories ul::-webkit-scrollbar {
  width: 1em;
}

.menu__categories ul::-webkit-scrollbar-track {
  box-shadow: transparent;
}

.menu__categories ul::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
}

.menu__categories ul {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-size: 1.3rem;
  letter-spacing: 1px;
  text-align: center;
  align-items: center;
  padding: 2rem;
  border: 1px solid var(--yellow);
  font-family: var(--poppins-font);
  overflow-x: auto;
  scroll-behavior: smooth;
}

.menu__categories ul a {
  color: var(--white);

}
.menu__categories ul a:hover {
  color: var(--pizza-red);
}

.menu__items {
  display: grid;
  grid-column: span 1;
  grid-template-columns: 1fr;
  grid-auto-rows: auto; 
  gap: 1rem;    
  width: 80vw;
  margin: 0 auto;
  height: auto; 
 
  
}

.pagination {
  color: var(--white);
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-top: 15rem;
}
.pagination li {
  cursor: pointer;
}

.pagination li.selected {
  color: var(--pizza-red);
}


/* Toppings*/
.toppings-section {
  display: flex;
  gap: 20px;
 width: 30vw;
}

.toppings-box {
  flex: 1;
  background-color: transparent;
  padding: 15px;
  border-radius: 8px;
  border: solid 4px var(--main-text-red);
  color: white;
  margin-top: 4rem;
  width:150%;
  min-height: fit-content;
}

.toppings-box h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.toppings-box ul {
  list-style-type: none;
  padding: 0;
}

.toppings-box li {
  margin-bottom: 5px;
}

/* MENU ITEM */
.menu-item {
  display: flex;
  flex-direction: column;
  
  padding: 2%;
  min-height:170px;
  max-height:fit-content;
  justify-content: space-around;
  position: relative;
  border: 2px solid var(--main-text-orange);

}
.menu-item__link {
  max-width: 100%;
  height: 21rem;
  max-height: 70%;
}
.menu-item__link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.menu-item h3 {
  font-size: 2rem;
  font-family: var(--poppins-font);
  font-weight: 450;
  letter-spacing: 1px;
}
/* MENU ITEM ATTRIBUTES */
.menu-item__attributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-item__attributes li {
  border: 1px solid rgba(255, 255, 255, 0.113);
  opacity: 0.7;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 0.4rem 0.6rem;
}



.add-to-cart-buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.add-to-cart-value {
  font-size: 1.5rem;
  color: var(--whitish-gray);
}

.add-to-cart-buttons button {
  cursor: pointer;
  font-size: 2rem;
  color: var(--whitish-gray);
  background-color: transparent;
  border: 1px solid transparent;
  transition: all ease-in-out 0.2s;
  text-align: center;
}


.menu__not-found {
  color: var(--white);
  margin-top: 3rem;
}

@media (hover: hover) {
  .add-to-cart-buttons button:hover {
    color: var(--yellow);
    transition: all ease-in-out 0.2s;
  }
}

/***************  RESPONSIVE ***************/
@media screen and (max-width: 600px) {
  .menu-item{
    min-height:fit-content;
    margin:0.2rem;
    overflow: hidden;;
    width: 90vw;
    max-height: fit-content;
  } 
  
  .menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto; 
    gap: 1rem;
    max-width: 96vw;
    left:0;
  }
  .menu__categories {
    grid-column: 1 / 2; 
    grid-row: 1 / 2;    
    flex-direction: row; 
    overflow-x: auto; 
    justify-content: center; 
  }

  .menu__items {
    grid-column: 1 / 2; 
    grid-row: 2 / 3;    
  }

  .toppings-section {
    grid-column: 1 / 2; 
    grid-row: 3 / 3;   

  }
  .menu__categories{

    padding:0;
    margin: 0 0 0.5rem 0.5rem 
  }
  .menu__items{
    margin:0;
    padding:0;
    max-width: 90vw;
  }

  .toppings-section{
    min-width: 90vw;
    min-height: fit-content;
  }
  .toppings-box {
    flex: 1;
    width: 100%;
    padding: 15px;
    overflow: visible;
   
  }
  .item-heading {
    font-size: 7rem;
    font-weight: 800;
    letter-spacing: 1px;
  }

  
}


@media screen and (min-width: 700px) {
  .menu {
    grid-template-columns: repeat(4, 1fr);
    gap: none;
   
  }

  .menu__categories {
    grid-column: span 1;
    position: sticky;
    top: 15%;
    display: flex;
    flex-direction: column;
    height: max-content;
    overflow-x: initial;
    scroll-behavior: initial;
    align-items: initial;
    padding-right: 2rem;
  }
  .menu__categories__search {
    width: 100%;
  }
  .menu__categories ul {
    flex-direction: column;
    justify-content: space-around;
    gap: initial;
    line-height: 5rem;
    overflow-x: initial;
    scroll-behavior: initial;
    min-width:12rem;
    

  }

  .menu__categories ul li a {
    color: var(--white);
    transition: all ease-in-out 0.3s;
  }

  .menu__categories ul li a:hover {
    color: var(--pizza-red);
    transition: all ease-in-out 0.3s;
  }

  .menu__categories li {
    border-bottom: 1px solid var(--yellow);
  }
  .menu__categories li:last-of-type {
    border-bottom: none;
  }
  .menu__items {
    grid-column: span 3;
    gap: 2rem;
    width: 70vw;
  }
  .pagination {
    position: absolute;
    bottom: 1%;
    right: 30%;
  }
}
.toppings-box {
  flex: 1;
  width: 50%;
  padding: 15px;
 
}

@media screen and (min-width: 1110px) {
  .menu__items {
    grid-template-columns: repeat(2, 1fr);
  }
  .menu__categories {
    width: 80%;
  }
  .menu__categories ul {
    padding: 2rem;
  }
  .menu {
    position: relative;
  }
  .pagination {
    margin: initial;
  }
  .pagination li.selected {
    color: var(--pizza-red);
  }
}
.toppings-box {
  flex: 1;
  width: 50%;
  padding: 15px;
 
}
@media screen and (min-width: 1440px) {
  .menu__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
.toppings-box {
  flex: 1;
  width: 50%;
  padding: 15px;
 
}