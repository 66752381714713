
  


.slider-container {
    width: 90%; 
    max-width: 100%; 
    margin: 0 auto; 
    padding: 0;
    color: black;
   overflow: hidden;
  
   overflow-y: hidden;
  }
 
.review-card::-webkit-scrollbar {
  width: 0; /* Safari and Chrome */
  height: 0; /* Safari and Chrome for horizontal scrollbar */
}
  
  .slider-title {
    text-align: center;
    font-size: 2.5rem;
    color: var(--main-text-orange);
    margin-bottom: 20px;
  }
  
  .review-card {
    height:450px;
    overflow-y: hidden;
    margin: 0 10px; 
    width:100%;
  }
  
  .card-content {
    text-align: center;
    border: 3px solid var(--main-text-orange);; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(255, 221, 2, 0.1);;
    padding: 20px;
    margin: 0 10px;
    background-color: var(--main-bg-light);
    height:80% ;
    width:75%;
    overflow-y: hidden;
  }
  
  .review-card h3 {
    margin: 10px 0;
  color: var(--main-text-red);
  font-weight: 600;
  font-size: 1.5rem;
  }

  .reviewText{
    font-size:1.2rem;
  text-align: justify;

  }


  
  svg.widget-svg{
    margin-top:20px
  }
  @media only screen and (min-width: 768px) {
   
  
    .review-card {
      width: 300px;
      margin:auto;
      overflow: scroll;
    }
  }
  
  @media only screen and (max-width: 768px) {
 
  .slider-container{
    width:100%
  }
  .review-card{
  min-height: fit-content;
  overflow: scroll;}
  
.slick-prev {
    left:-5%;
    z-index: 1; 
  }
  .slick-next {
    right:-5%;
    z-index: 1; 
  }
  }